/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { motion, AnimatePresence } from "framer-motion";
import { sectionVariant, showContent } from "../../utils/variants";

import { UseLangContext } from "../../contexts/Lang";
import IcArrow from "../../assets/images/ic-left-arrow.png";
import Accordion from "../../components/Accordion";
// import PdfViewer from "../../components/PdfViewer";

type Props = {};

const Product = (props: Props) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const { id } = useParams();
  const { lang, getExistProduct } = UseLangContext();
  const [product, setProduct] = useState<any>({});
  const [activeTab, setActiveTab] = useState<string>("product_list");
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(-1);

  useEffect(() => {
    const productExist = getExistProduct(id);
    document.title = `Omega Solusindo Bersama | ${productExist.title}`;
    setProduct(productExist);
  }, [lang]);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };

  return (
    <div className="px-6 lg:px-20 pb-10 pt-10">
      {/* Navigation */}
      <div className="flex justify-between  mb-8 lg:mb-10">
        <Link
          to="/"
          className="cursor-pointer rounded-[30px] flex items-center justify-center px-4 py-2 space-x-4 bg-white border border-solid border-[#A0A0A0]"
        >
          <img src={IcArrow} alt="back" />
          <p className="block text-[16px]">Back</p>
        </Link>

        <div className="flex space-x-4 items-center justify-center">
          <Link to="/">Home</Link>
          <span>&gt;</span>
          <div className="uppercase text-[#019EE2] border-b border-solid border-[#019ee2]">
            {product.title}
          </div>
        </div>
      </div>
      <div className="py-10 mb-[50px] flex flex-col items-center justify-center rounded-[20px] border border-solid border-[#A0A0A0] bg-[#f5f5f5] text-center">
        <img
          className="w-[250px] max-h-[150px] object-cover"
          src={product.imageUrl}
          alt={product.title}
        />
      </div>
      {/* DESCRIPTION */}
      <div className="mb-10">
        <div className="flex flex-col justify-center w-max mb-[30px]">
          <h3 className="text-3xl text-[#383838] font-semibold w-max">
            Introduction
          </h3>
        </div>
        <p
          className="text-sm md:text-[16px] text-justify font-normal leading-[35px] text-[#444]"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(product.description),
          }}
        ></p>
      </div>
      {/* TAB LIST */}
      <div className="flex mb-[50px] items-center space-x-4 overflow-x-auto md:overflow-x-hidden">
        <button
          onClick={() => setActiveTab("product_list")}
          className={`${
            activeTab === "product_list"
              ? "bg-[#019EE2] text-white"
              : "bg-white text-[#6a6a6a] border border-solid border-[#A0A0A0]"
          } rounded-[30px] text-center py-[20px] px-[40px] `}
        >
          Products
        </button>
        <button
          onClick={() => setActiveTab("application_list")}
          className={`${
            activeTab === "application_list"
              ? "bg-[#019EE2] text-white"
              : "bg-white text-[#6a6a6a] border border-solid border-[#A0A0A0]"
          } rounded-[30px] text-center py-[20px] px-[40px] `}
        >
          Applications
        </button>
        <button
          onClick={() => setActiveTab("certificate_list")}
          className={`${
            activeTab === "certificate_list"
              ? "bg-[#019EE2] text-white"
              : "bg-white text-[#6a6a6a] border border-solid border-[#A0A0A0]"
          } rounded-[30px] text-center py-[20px] px-[40px] `}
        >
          Certificate
        </button>
      </div>
      {/* Product List */}
      {activeTab === "product_list" &&
        product.productsInCategory?.map((item, idx) => (
          <Accordion
            product={item}
            key={item.id}
            idx={idx}
            urlId={id}
            onOpen={handleAccordionClick}
            activeAccordion={openAccordionIndex}
          />
        ))}

      {/* Applications Product */}
      <AnimatePresence>
        {activeTab === "application_list" && (
          <motion.ul
            variants={sectionVariant}
            initial="hidden"
            whileInView="visible"
            className="grid grid-cols-1 lg:grid-cols-2 gap-6"
          >
            {product.applicationsInCategory?.map((application, idx) => (
              <motion.li
                custom={idx}
                variants={showContent}
                className="flex items-center space-x-4 p-[25px]  border border-solid border-[#019EE2] rounded-3xl"
                key={idx}
              >
                <div className="w-3 h-3 bg-[#2F5597] rounded-full"></div>
                <span className="font-medium">{application}</span>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      {/* Certificate Product */}
      {activeTab === "certificate_list" ? (
        product?.certificatesInCategory?.length > 0 ? (
          product.certificatesInCategory.map((certificate, idx) => (
            <div className="mb-6 " key={idx}>
              {/* <PdfViewer key={idx} pdf={certificate} /> */}

              <div className="h-[750px] border border-gray-100 border-solid">
                <object
                  data={certificate}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  dangerouslySetInnerHTML={{
                    __html: `<embed src="${certificate}" type="application/pdf" width="100%" height="100%" />`,
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <span className="mx-auto text-xl md:text-3xl w-full">
            No Certificate
          </span>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Product;
