import React from "react";
// import { SocialIcon } from "react-social-icons";
import { useForm, SubmitHandler } from "react-hook-form";
import DOMPurify from "dompurify";

import EmailImg from "../../assets/images/ContactUs-Icon/Email.svg";
import WebImg from "../../assets/images/ContactUs-Icon/Website.svg";
import LocImg from "../../assets/images/ContactUs-Icon/Location.svg";
import TelImg from "../../assets/images/ContactUs-Icon/Tel.svg";
import IgImg from "../../assets/images/sosmed/IG.svg";
import { Homepage } from "../../@types/lang";

type Props = {
  lang: Homepage;
};

type Inputs = {
  name: string;
  email: string;
  message: string;
};

const Contact = ({ lang }: Props) => {
  const { register, handleSubmit } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    window.location.href = `mailto:admin@omegasolusindo.co.id?subject=&body=Hi, my name is ${formData.name}. ${formData.message} ${formData.email}`;
  };

  return (
    <div className="relative bg-[#1C323F] flex flex-col lg:flex-row  lg:space-y-0 space-y-6 justify-center px-10 lg:px-20 py-8 pb-6 before:block before:absolute before:bottom-0 before:left-[50%] before:-translate-x-[50%] before:w-[90%] before:lg:w-[95%] before:h-[2px] before:bg-[#5b5b5b]">
      <div className="flex flex-col justify-center space-y-6 w-full lg:w-[62%] lg:pr-10 text-justify">
        <h3 className="uppercase text-white text-[40px] font-semibold">
          {lang.contact_title}
        </h3>
        <p
          className="text-sm lg:text-lg text-white"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(lang.contact_text),
          }}
        ></p>
        <div className="flex flex-col space-y-4 justify-center">
          <div className="flex items-center space-x-5">
            <img src={EmailImg} alt="email" />
            <p className="text-sm lg:text-lg text-white">
              admin@omegasolusindo.co.id
            </p>
          </div>
          <div className="flex items-center space-x-5">
            <img src={WebImg} alt="website" />
            <p className="text-sm lg:text-lg text-white">
              {" "}
              www.omegasolusindobersama.co.id
            </p>
          </div>
          <div className="flex items-center space-x-5">
            <img src={TelImg} alt="Telephone" />
            <p className="text-sm lg:text-lg text-white">+62 21 21384301</p>
          </div>
          <div className="flex items-center space-x-5">
            <img src={LocImg} alt="location" />
            <p className="text-sm lg:text-lg text-white">
              Jl. Transyogi KM.3, Cibubur Times Square Blok C1 No. 40,
              Jatikarya, Jatisampurna, Bekasi 17435
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-5 text-white">
          <p>Our Social Media</p>
          <div className="w-[30px] h-[2px] bg-white"></div>
          {/* Social Media */}
          <a
            href="https://instagram.com/omegasolusindobersama"
            target="_blank"
            rel="noreferrer"
            className="w-12 h-12"
          >
            <img src={IgImg} alt="ig" />
          </a>
          <a
            href="https://www.linkedin.com/company/pt-omega-solusindo-bersama/"
            target="_blank"
            rel="noreferrer"
            className="w-12 h-12"
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png" alt="ig" />
          </a>
          {/* <SocialIcon
            url="https://instagram.com/omegasolusindobersama"
            fgColor="#fff"
          /> */}
          {/* <SocialIcon url="https://www.linkedin.com/in/pt-omega-solusindo-bersama-533a5185/?originalSubdomain=id" /> */}
        </div>
      </div>
      <div className="bg-white rounded-[20px] flex flex-col space-y-5 px-8 py-6 w-full lg:w-[38%]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex space-y-6 flex-col justify-center"
        >
          <h4 className="text-black text-[21px] leading-[30px]">
            {lang.contact_subtitle}
          </h4>
          <input
            {...register("name")}
            type="text"
            className="inputField"
            placeholder="Full Name*"
            required
          />
          <input
            {...register("email")}
            type="email"
            className="inputField"
            placeholder="Email*"
            required
          />
          <textarea
            {...register("message")}
            className="inputField"
            placeholder="Message"
          ></textarea>
          <button
            className="bg-[#019EE2] hover:bg-[#2F5597] py-[20px] px-[40px] w-[200px] mx-auto text-white rounded-[30px]"
            type="submit"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
