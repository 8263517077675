import EnvironmentImage from "../assets/images/new/banner_2.png";
import MechanicalImg from "../assets/images/mechanical.png";
import ElectricalImg from "../assets/images/electrical.png";

import ElectOne from "../assets/images/electrical_service_image/1.jpg";
import ElectTwo from "../assets/images/electrical_service_image/2.jpg";
import ElectThree from "../assets/images/electrical_service_image/3.jpg";
import ElectFour from "../assets/images/electrical_service_image/4.jpg";
import ElectFive from "../assets/images/electrical_service_image/5.jpg";
import ElectSix from "../assets/images/electrical_service_image/6.jpg";
import ElectSeven from "../assets/images/electrical_service_image/7.jpg";

import MechaOne from "../assets/images/mechanical_service_image/1.jpg";
import MechaTwo from "../assets/images/mechanical_service_image/2.jpg";
import MechaThree from "../assets/images/mechanical_service_image/3.jpg";

import EnvOne from "../assets/images/environment_service_image/1.jpeg";
import EnvTwo from "../assets/images/environment_service_image/2.jpeg";
import EnvThree from "../assets/images/environment_service_image/3.jpeg";
import EnvFour from "../assets/images/environment_service_image/4.jpeg";

export const serviceImage = {
  imgEnv: EnvironmentImage,
  imgMech: MechanicalImg,
  imgElec: ElectricalImg,
};

export const serviceImgElectrical:string[] = [
  ElectOne,
  ElectTwo,
  ElectThree,
  ElectFive,
  ElectFour,
  ElectSix,
  ElectSeven,
];

export const serviceImgMechanical:string[] = [MechaOne, MechaTwo, MechaThree];

export const serviceImgEnvironment: string[] = [EnvOne, EnvTwo, EnvThree,EnvFour];
