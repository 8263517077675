import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "../assets/images/logo-osb-new.png";
import EnglishLogo from "../assets/images/icon-lang/eng.png";
import IndonesiaLogo from "../assets/images/icon-lang/indo.jpg";
import { HashLink } from "react-router-hash-link";
import { useScrollPosition } from "../hooks/useScrollPositiion";
import { UseLangContext } from "../contexts/Lang";
import utils from "../utils/utils";

type Props = {};

export default function Header(props: Props) {
  const [navExtend, setNavExtend] = useState<boolean>(false);
  const { setLanguage, lang } = UseLangContext();

  const { scrollWithOffset } = utils;

  const scrollPosition = useScrollPosition();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const selectLang = (data) => {
    setLanguage(data);
  };

  return (
    <>
      <div
        className={classNames(
          scrollPosition
            ? "py-[10px] bg-[#f4f4f4] shadow-sm shadow-[#f4f4f4]/40"
            : "py-[18px] bg-transparent",
          "transition-all z-40 sticky top-0 flex items-center px-6 md:px-20 lg:px-20"
        )}
      >
        <HashLink
          scroll={(el) => scrollWithOffset(el, 0)}
          to="/#home"
          className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
        >
          <img src={Logo} alt="logo" className="w-[140px] object-cover" />
        </HashLink>

        {/* MOBILE */}
        <div className="block lg:hidden ml-auto">
          <button
            className="bg-[#019EE2] rounded-full p-2"
            onClick={() => setNavExtend(true)}
          >
            {/* icon from heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <AnimatePresence>
            {navExtend && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: 0 }}
                className="block lg:hidden fixed h-full left-0 bottom-0 top-0 w-full z-50 bg-[#f4f4f4]"
              >
                <div
                  className="absolute top-10 right-10"
                  onClick={() => setNavExtend(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
                <nav className="h-screen flex flex-col justify-center flex-shrink-0 space-y-10 items-center text-center">
                  <HashLink
                    onClick={() => setNavExtend(false)}
                    scroll={(el) =>
                      scrollWithOffset(el, scrollPosition ? -50 : -65)
                    }
                    to="/#home"
                    className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
                  >
                    Home
                  </HashLink>
                  <HashLink
                    onClick={() => setNavExtend(false)}
                    scroll={(el) =>
                      scrollWithOffset(el, scrollPosition ? -100 : -116)
                    }
                    to="/#about"
                    className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
                  >
                    About Us
                  </HashLink>
                  <HashLink
                    onClick={() => setNavExtend(false)}
                    scroll={(el) =>
                      scrollWithOffset(el, scrollPosition ? -50 : -65)
                    }
                    to="/#service"
                    className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
                  >
                    Service
                  </HashLink>
                  <HashLink
                    onClick={() => setNavExtend(false)}
                    scroll={(el) =>
                      scrollWithOffset(el, scrollPosition ? -50 : -65)
                    }
                    to="/#prod"
                    className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
                  >
                    Product
                  </HashLink>
                  <HashLink
                    onClick={() => setNavExtend(false)}
                    scroll={(el) =>
                      scrollWithOffset(el, scrollPosition ? -50 : -65)
                    }
                    to="/#contact"
                    className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
                  >
                    Contact Us
                  </HashLink>
                  <div className="flex items-center space-x-4">
                    <select
                      onChange={(e) => selectLang(e.target.value)}
                      className="px-4 bg-transparent text-[#6A6A6A] text-lg active:outline-none focus:outline-none"
                      value={lang}
                    >
                      <option value="en">ENG</option>
                      <option value="id">IND</option>
                    </select>

                    {lang === "en" ? (
                      <img
                        className="w-10 h-10 rounded-full"
                        src={EnglishLogo}
                        alt="jpg"
                      />
                    ) : (
                      <img
                        className="w-10 h-10 rounded-full"
                        src={IndonesiaLogo}
                        alt="jpg"
                      />
                    )}
                  </div>
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* DESKTOP */}
        <nav className="hidden ml-auto lg:flex flex-shrink-0 space-x-4 items-center">
          <HashLink
            scroll={(el) => scrollWithOffset(el, scrollPosition ? -50 : -65)}
            to="/#home"
            className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
          >
            Home
          </HashLink>
          <HashLink
            scroll={(el) => scrollWithOffset(el, scrollPosition ? -100 : -116)}
            to="/#about"
            className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
          >
            About Us
          </HashLink>
          <HashLink
            scroll={(el) => scrollWithOffset(el, scrollPosition ? -50 : -65)}
            to="/#service"
            className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
          >
            Service
          </HashLink>
          <HashLink
            scroll={(el) => scrollWithOffset(el, scrollPosition ? -50 : -65)}
            to="/#prod"
            className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
          >
            Product
          </HashLink>

          <HashLink
            scroll={(el) => scrollWithOffset(el, scrollPosition ? -50 : -65)}
            to="/#contact"
            className="hover:text-[#2F5597] hover:font-bold font-normal text-lg cursor-pointer"
          >
            Contact Us
          </HashLink>
          <div className="flex items-center space-x-4">
            <select
              onChange={(e) => selectLang(e.target.value)}
              className="px-4 bg-transparent text-[#6A6A6A] text-lg active:outline-none focus:outline-none"
              value={lang}
            >
              <option value="en">ENG</option>
              <option value="id">IND</option>
            </select>

            {lang === "en" ? (
              <img
                className="w-10 h-10 rounded-full"
                src={EnglishLogo}
                alt="jpg"
              />
            ) : (
              <img
                className="w-10 h-10 rounded-full"
                src={IndonesiaLogo}
                alt="jpg"
              />
            )}
          </div>
        </nav>
      </div>
    </>
  );
}
