import OpsisImg from "../assets/images/opsis.png";
import SkiImg from "../assets/images/ski_icon.png";
import FavesImg from "../assets/images/fives_icon.png";
import {
  opsisProductImage,
  skiProductImage,
  fivesProductImage,
  certiFives,
  certiSki,
  certiOpsis,
} from "../data/products";
import {
  serviceImage,
  serviceImgElectrical,
  serviceImgMechanical,
  serviceImgEnvironment,
} from "../data/services";
import { Lang } from "../@types/lang";

const en: Lang = {
  // HOMEPAGE
  homepage: {
    banner_title: "Outstanding Service, Outstanding Quality, Outstanding You.",
    about_text:
      "Was established on February 29, 2012, PT OMEGA SOLUSINDO BERSAMA is a company engaged in Mechanical, Electrical and Environment. Several professionals in their fields who share the same vision have joined since 2012 to help make PT. OMEGA SOLUSINDO BERSAMA a trusted leading service company. Supported by excellent team of individuals who possess high skills, knowledge, experience and expertise. ",
    about_title: "About Us",
    about_subtitle:
      "We are a company engaged in Engineering, and Environmental Service",
    about_vision:
      "Become one of the leading national companies in the mechanical, electrical & environment fields, which continues to develop following future technological advances.",
    about_mission:
      "Providing professional services with quality and satisfaction to customers while still meeting occupational health and safety standards and the environment in accordance with applicable regulations.",
    about_value:
      "PT Omega Solusindo Bersama provides quality assurance & the best quality of services to customers that is professionally managed to meet customers wishes, needs and satisfaction and become a contribution to the nation and state.",
    service_title: "Our Service",
    service_subtitle: "Provides the best service for you",
    product_title: "Our Product",
    product_subtitle: "We are authorized distributor",
    contact_text:
      "Contact us for quote or if you want to know more about our services and products.",
    contact_title: "GET IN TOUCH",
    contact_subtitle: "Use the form below to drop us an email",
    client_title: "Our Client",
  },
  // Product
  categoryProducts: [
    {
      id: "1",
      title: "Opsis",
      imageUrl: OpsisImg,
      description: `
        <p style="margin-bottom:1rem;">
        OPSIS environmental monitoring systems have been tested and approved by a number of internationally recognized institutes and authorities. OPSIS environmental monitoring systems are characterized by their reliability, durability, and low maintenance costs; numerous references will vouch for this. 
        </p>
        <p style="margin-bottom:1rem;">
        The OPSIS system is based on the non-contact DOAS/FTIR method, using an optical path that can operate across the duct. The optical light is transported in an optical fibre to the analyzer and one analyzer can operate several paths providing e.g flue gas flow rates and dust concentrations. The measurement results are often forwarded in real-time to the process control system using any of the standard industrial communication protocols supported. From there, the results can be used e.g. emissions monitoring and process optimization.
        </p>
      `,
      shortDescription: `A single OPSIS system can measure all relevant gas components such as NOX, SO2, CO, CO2, H2O, HCl, HF, NH3, CH4, and Hg`,
      productsInCategory: [
        {
          title: "EMITTER & RECEIVER FOR CEMS MANUAL OR AUTOMATIC  CALIBRATION",
          description:
            "Emitter and Receiver unit creates a light path across the internal diameter of a stack or duct for an OPSIS emissions monitoring system. Light is generated by a xenon lamp in the emitter and projected to the receiver. From the receiver, it is passed to the analyser through a fibre optic cable. Both the emitter and the receiver are mounted on external pipe flanges. The emitter uses a power supply unit, which requires an electrical connection to mains.",
          imageUrl: opsisProductImage.img1,
          id: 1,
        },
        {
          title: "MULTI-COMPONENT ANALYSERS FOR CEMS",
          description:
            "OPSIS Analysers receives light from one or more light paths via a fibre optic cable and provides data for presentation through OPSIS software. The analyser has a high-performance spectrometer. The system refines results by calculating margins of error for each value and by noting the proportion of transmitted light received by the analyser. This allows both data and system verification.",
          imageUrl: opsisProductImage.img2,
          id: 2,
        },
        {
          title: "OXYGEN ANALYSER FOR CEMS ",
          description:
            "The OPSIS Oxygen analyser is designed for measuring oxygen in industrial, process and continuous emissions monitoring applications. Since the oxygen analyser measures the oxygen contents in the flue gas directly, there is no need for sample extraction systems.",
          imageUrl: opsisProductImage.img3,
          id: 3,
        },
        // {
        //   title: "POWER SUPLLY FOR EMITTER & RECEIVER",
        //   description:
        //     "The Power Supply operates the xenon lamps used in OPSIS light emitters. It is connected to the ignition unit mounted in the emitter housing adjacent to the lamp itself. Once the lamp is illuminated, the main supply delivers a stabilized light maintaining current.",
        //   imageUrl: opsisProductImage.img4,
        //   id: 4,
        // },
        // {
        //   title: "FIBRE OPTIC",
        //   description:
        //     "Fibre optic cables are required in an OPSIS system to carry received light from a light path to the analyser. Eight types of fibre optic cables, detailed on the reverse of this product sheet, are available. ",
        //   imageUrl: opsisProductImage.img5,
        //   id: 5,
        // },
      ],
      applicationsInCategory: [
        "Aluminium Smelters",
        "Cement Industry",
        "Chemical Industry",
        "Mercury Monitoring",
        "Mining & Smelting",
        "Power Plants",
        "Steel Plants",
        "Sulfuric Acid Production Plants",
        "And More",
      ],
      certificatesInCategory: certiOpsis,
    },
    {
      id: "2",
      title: "Ski",
      imageUrl: SkiImg,
      description: `
        <p style="margin-bottom:1rem;"> 
        The quality of SKI products is proven by measurements on in-house calibration bench and several hundred calibration certificates issued by the Landesbetriebes für Mess- und Eichwesen and other 3rd party labratories.
        </p>

        SKI cover the broad field of <div style="font-weight: bold; display:inline;">Differential Pressure </div>  based flow measurement technology including services around this field. Why Differential Pressure for CEMS?
        <ul style="list-style: none;">
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Universally and world-wide accepted (and accepted by virtually all authorities).</li>
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Extremely robust and long-life.</li>
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Economic technology.</li>
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Suitable for most applications.</li>
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div>Highly flexible.</li>
          <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Very low maintenance requirement (no moving parts, no wear/tear/consumables).</li>
        </ul>
        `,
      shortDescription:
        "SKI instruments are used wherever gases, saturated or superheated steam or liquid flows have to be measured reliably",
      productsInCategory: [
        {
          title: "AccuFlo®QAL",
          description:
            "SKI AccuFlo®QAL is an automatic measuring system certified according to the QAL 1 for fluegas volume flow measurement. The system is easy to install, operate and to put into operation. Its highly flexible design allows the instrument to be retrofitted to existing stacks and ducts and its robust design principle makes it suitable for applications in which other stack flow meter technology can no longer be used due to their measuring principle. This applies to applications with very high exhaust gas temperatures, water vapor saturated gases and flue gases with high dust or particle content.",
          imageUrl: skiProductImage.img1,
          id: 1,
        },
        {
          title: "Accu Mind",
          description:
            "The AccuMind® offers numerous calculation algorithms in one device with a uniform operating philosophy and is easy to program.",
          imageUrl: skiProductImage.img2,
          id: 2,
        },
      ],
      applicationsInCategory: [
        "Power Industries",
        "Building and Raw Materials Industry",
        "Environmental Technologies",
        "Chemical and Petrochemical Industry",
        "Steel and Metallurgic Industry",
        "And More",
      ],
      certificatesInCategory: certiSki,
    },
    {
      id: "3",
      title: "Fives",
      imageUrl: FavesImg,
      description: `As part of the firing system, Fives Pillard take on all the tasks that arise, from fuel storage and internal transport to the burners and combustion chambers, including the associated safety and automation technology and the necessary auxiliary systems. Fives Pillard offers advanced <div style="display:inline; font-weight:bold">Backscatter</div> technology to monitor stacks and bag filter leaks. It is the ultimate solution for keeping track of dust emissions and meeting industry standards and regulations. The equipment is highly reliable, thanks to a fiber optic system that enables the electronics to work remotely from the stack.`,
      shortDescription:
        "A wide range of Dust Emission Monitors covering most industrial applications",
      productsInCategory: [
        {
          title: "GP4000-H",
          description:
            "With advanced back scattering technology for stacks and after bag filter monitoring, it is the ultimate solution to monitor dust and opacity emission while meeting regulations and certifications. Through the back scattering technology, the LED light beam offers accurate measurement (insensitive to ambient light). The self-checking functions and correction soiling system avoid a distorted measurement signal.",
          imageUrl: fivesProductImage.img1,
          id: 1,
        },
        {
          title: "GP4000H-C",
          description:
            "Fives’ latest solution for low dust concentration to ensure continuous measure and control dust emissions to all stacks industries. ",
          imageUrl: fivesProductImage.img2,
          id: 1,
        },
      ],
      applicationsInCategory: [
        "Air and waste treatment",
        "Cement",
        "Ferrous metals",
        "Minerals",
        "And More",
      ],
      certificatesInCategory: certiFives,
    },
  ],

  // Service
  services: [
    {
      id: 1,
      title: "Environment",
      description: `Continuous Emission Monitoring System (CEMS) is a method of monitoring the emissions from the smokestacks of industrial sites such as power stations, manufacturing plants and other facilities where combustion of fuel sources is in occurrence. CEMS with leading technology is essential for real-time surveillance and monitoring of the chimney, to ensure emissions can be strictly controlled in accordance with permitted emission standards. It is a legal requirement in many countries around the world. On May 24, 2021, the Minister of Environment and Forestry Regulation No. 13 of 2021 on Continuous Emission Monitoring System for Industrial Emissions was enacted in Indonesia and came into effect on the same day. This regulation requires certain industries to install a Continuous Emission Monitoring System (CEMS) and to connect the data to the Continuous Industrial Emission Monitoring Information System (SISPEK) operated by the government. 
        <br/>
        <br/>
        PT Omega Solusindo Bersama specialized in Continuous Emission Monitoring System (CEMS). Our scope of works includes supply, develop, install, integrate, test, calibrate and commissioning of a complete monitoring system. Our people have vast experiences in environmental monitoring services and system. PT Omega Solusindo Bersama partners with some of the most advanced CEMS developers for :
          <ol style="list-style: decimal; padding-left:1rem; padding-top:1rem;">
            <li style="padding: 0.2rem 0"><a href="/product/1" style="color:#019EE2;border-bottom:1px solid #019ee2;">Gas Analyser</a></li>
            <li style="padding: 0.2rem 0"><a href="/product/3" style="color:#019EE2;border-bottom:1px solid #019ee2;">Opacity & Particulate Analyser</a></li>
            <li style="padding: 0.2rem 0"><a href="/product/2" style="color:#019EE2;border-bottom:1px solid #019ee2;">Flow, Temperature and Pressure Analyser</a></li>
          </ol>
          <br/>
          We offer a wide range of testing and analytical services, utilizing the latest instrumentation. PT Omega Solusindo Bersama has extensive experience in performing CEMS calibration services including:
          <br/><br/>
          <ul style="list-style: none;">
            <div style="display:flex; align-items:center; gap:3rem" id="list-content-environment">
              <div>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Relative Accuracy Test Audits (RATA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Relative Accuracy Audit (RAA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Response Correlation Audit (RCA)</li>
              </div>
              <div>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Cylinder Gas Audit (CGA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Data Interfacing System (DIS)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Data Acquisition System (DAS)</li>
              </div>
            </div>
          </ul>
          <br/>
          We also providing consultancies in relation to environmental requirements, and information systems.
        `,
      imageUrl: serviceImage.imgEnv,
      imageProductService: serviceImgEnvironment,
    },
    {
      id: 2,
      title: "Mechanical",
      imageUrl: serviceImage.imgMech,
      description:
        "One of our specialties is Plumbing work which includes Main Equipment such as Transfer Pumps, Booster Pumps, Sump Pumps, as well as piping work using various types of pipes such as Black Steel, Galvanized, PPR, and PVC, including various types of valve work such as Pressure Reduce Valve (PRV), Water Hammer, Master Control Valve (MCV) and so on. We also provide Sanitaire according to your needs, budget, or for other special needs that support performance and support the desired interior aesthetic. PT Omega SOLUSINDO BERSAMA also provides Hydrant and Sprinkler work. From Electric Hydrant Pumps, Engine Hydrant Pumps, and Jockey Pumps, as well as piping and valve work that supports Hydrant and Sprinkler systems to function according to the standards of the relevant agencies. Not only the main equipment and its installation, but also other accessories such as the Hydrant Box, Siamese Connection Head, and Lightweight Fire Extinguisher. Other mechanical works that is no less important in construction work that we do, namely Air Conditioner Works, both from SOHO Standards such as Split wall AC type, Ceiling conceal, Cassette, and Ceiling Suspended. Not only for small applications, but we also provide capacity for building and office applications that require reliable accuracy and professionalism.",
      imageProductService: serviceImgMechanical,
    },
    {
      id: 3,
      title: "Electrical",
      imageUrl: serviceImage.imgElec,
      description:
        "PT. Omega Solusindo Bersama is also working on Main Electrical Works from Standard Electrical Installation of Buildings, both External and Internal, including Underground Cable Installation (TUFUR) and Installation using BusBar and Main Electrical Equipment range from Small Distribution Panels supply, Low Voltage Panel, to Specific Electrical Panels. We also provide and install Generator Sets, Step Down and Step Up Transformers, and Medium Voltage Panels (Cubicle sets). Other electrical work that is no less important is grounding and lightning rod installation.",
      imageProductService: serviceImgElectrical,
    },
  ],

  // popular product item
  popularProducts: [
    {
      title: "EMITTER & RECEIVER FOR CEMS MANUAL OR AUTOMATIC  CALIBRATION",
      description:
        "Emitter and Receiver unit creates a light path across the internal diameter of a stack or duct for an OPSIS emissions monitoring system. Light is generated by a xenon lamp in the emitter and projected to the receiver. From the receiver, it is passed to the analyser through a fibre optic cable. Both the emitter and the receiver are mounted on external pipe flanges. The emitter uses a power supply unit, which requires an electrical connection to mains.",
      imageUrl: opsisProductImage.img1,
      id: 1,
      idCategoryProduct: 1,
    },
    {
      title: "MULTI-COMPONENT ANALYSERS FOR CEMS",
      description:
        "OPSIS Analysers receives light from one or more light paths via a fibre optic cable and provides data for presentation through OPSIS software. The analyser has a high-performance spectrometer. The system refines results by calculating margins of error for each value and by noting the proportion of transmitted light received by the analyser. This allows both data and system verification.",
      imageUrl: opsisProductImage.img2,
      id: 2,
      idCategoryProduct: 1,
    },
    {
      title: "OXYGEN ANALYSER FOR CEMS ",
      description:
        "The OPSIS Oxygen analyser is designed for measuring oxygen in industrial, process and continuous emissions monitoring applications. Since the oxygen analyser measures the oxygen contents in the flue gas directly, there is no need for sample extraction systems.",
      imageUrl: opsisProductImage.img3,
      id: 3,
      idCategoryProduct: 1,
    },
    {
      title: "AccuFlo®QAL",
      description:
        "SKI AccuFlo®QAL is an automatic measuring system certified according to the QAL 1 for fluegas volume flow measurement. The system is easy to install, operate and to put into operation. Its highly flexible design allows the instrument to be retrofitted to existing stacks and ducts and its robust design principle makes it suitable for applications in which other stack flow meter technology can no longer be used due to their measuring principle. This applies to applications with very high exhaust gas temperatures, water vapor saturated gases and flue gases with high dust or particle content.",
      imageUrl: skiProductImage.img1,
      id: 4,
      idCategoryProduct: 2,
    },
    {
      title: "Accu Mind",
      description:
        "The AccuMind® offers numerous calculation algorithms in one device with a uniform operating philosophy and is easy to program.",
      imageUrl: skiProductImage.img2,
      id: 2,
      idCategoryProduct: 2,
    },
    {
      title: "GP4000-H",
      description:
        "With advanced back scattering technology for stacks and after bag filter monitoring, it is the ultimate solution to monitor dust and opacity emission while meeting regulations and certifications. Through the back scattering technology, the LED light beam offers accurate measurement (insensitive to ambient light). The self-checking functions and correction soiling system avoid a distorted measurement signal.",
      imageUrl: fivesProductImage.img1,
      id: 4,
      idCategoryProduct: 2,
    },
    {
      title: "GP4000H-C",
      description:
        "Fives’ latest solution for low dust concentration to ensure continuous measure and control dust emissions to all stacks industries.",
      imageUrl: fivesProductImage.img2,
      id: 2,
      idCategoryProduct: 2,
    },
  ],
};

export default en;
