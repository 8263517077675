import React from "react";
import About from "../components/homepage/About";
import Services from "../components/homepage/Services";
import Products from "../components/homepage/Products";

import { UseLangContext } from "../contexts/Lang";
import Client from "../components/homepage/Client";

type Props = {};

const HomePage = (props: Props) => {
  const {
    t: { homepage, categoryProducts, services, popularProducts },
  } = UseLangContext();

  return (
    <div>
      {/* ABOUT US */}
      <section className="h-full" id="home">
        <About lang={homepage} />
      </section>
      {/* SERVICE */}
      <section className="h-full py-[40px] lg:py-[60px]" id="service">
        <Services services={services} lang={homepage} />
      </section>
      {/* PRODUCT */}
      <section className="h-full py-[40px] lg:py-[60px]" id="prod">
        <Products
          products={categoryProducts}
          lang={homepage}
          popularProducts={popularProducts}
        />
      </section>
      {/* Client */}
      <section className="h-full py-[40px] lg:py-[60px]" id="client">
        <Client lang={homepage} />
      </section>
    </div>
  );
};

export default HomePage;
