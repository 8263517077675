import Marquee from "react-fast-marquee";

import { clients } from "../../data/client";
import { Homepage } from "../../@types/lang";

type Props = {
  lang: Homepage;
};

const Client = ({ lang }: Props) => {
  return (
    <>
      <div>
        <div className="px-6 lg:px-20 flex flex-col space-y-4 mb-8 lg:mb-10">
          <h5 className="text-[#019EE2] text-xl font-semibold">
            {lang.client_title}
          </h5>
        </div>
        {/* LIST CLIENT */}
        <Marquee direction="right" speed={50} pauseOnClick pauseOnHover>
          {clients.map((client, idx) => (
            <div className="w-max h-max mx-4" key={idx}>
              <img
                src={client}
                alt={`client-${idx}`}
                className=" object-cover  w-max max-w-[280px]  max-h-[140px]"
              />
            </div>
          ))}
        </Marquee>
        <div className="px-6 lg:px-20 flex items-center space-x-4 h-max overflow-x-auto"></div>
      </div>
    </>
  );
};

export default Client;
