import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/homepage/Contact";
import { UseLangContext } from "../contexts/Lang";

type Props = {
  children: React.ReactNode;
};

const index = ({ children }: Props) => {
  const {
    t: { homepage },
  } = UseLangContext();
  return (
    <div className="max-w-[1600px] mx-auto relative">
      <Header />
      <section className="pb-8">{children}</section>
      {/* CONTACT US */}
      <section className="h-full" id="contact">
        <Contact lang={homepage} />
      </section>
      <div className="fixed bottom-10 right-10 z-10 rounded-full">
          <a
            href="https://wa.me/6281333347484"
            target="_blank"
            rel="noreferrer"
            className="w-12 h-12 rounded-full"
          >
            <img src="https://cdn.iconscout.com/icon/free/png-256/whatsapp-circle-1868968-1583132.png" alt="ig" className="w-12 h-12"/>
          </a>
        {/* <SocialIcon
            url="https://wa.me/6281333347484"
            network="whatsapp"
            fgColor="#000"
          /> */}
      </div>
      <Footer />
    </div>
  );
};

export default index;
