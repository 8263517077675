import ClientImg1 from "../assets/images/client/1.svg";
import ClientImg2 from "../assets/images/client/2.svg";
import ClientImg3 from "../assets/images/client/3.svg";
import ClientImg4 from "../assets/images/client/4.svg";
import ClientImg5 from "../assets/images/client/5.svg";
import ClientImg6 from "../assets/images/client/6.svg";
import ClientImg7 from "../assets/images/client/7.svg";
import ClientImg8 from "../assets/images/client/8.svg";
import ClientImg9 from "../assets/images/client/9.svg";

export const clients: string[] = [
  ClientImg1,
  ClientImg2,
  ClientImg3,
  ClientImg4,
  ClientImg5,
  ClientImg6,
  ClientImg7,
  ClientImg8,
  ClientImg9,
];
