/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper";
import DOMPurify from "dompurify";
import "swiper/css";
import "swiper/css/navigation";

import { UseLangContext } from "../../contexts/Lang";
import IcArrow from "../../assets/images/ic-left-arrow.png";
import IcArrowRight from "../../assets/images/arrow-right-slider.svg";
import IcArrowLeft from "../../assets/images/arrow-left-slider.svg";

type Props = {};


const Service = (props: Props) => {
  const { id } = useParams();
  const { lang, getExistService } = UseLangContext();
  const [service, setService] = useState<any>({});
  const navigate = useNavigate();
  const [images, setImages] = useState<any>(null);

  useEffect(() => {
    const serviceExist = getExistService(id);
    document.title = `Omega Solusindo Bersama | ${serviceExist.title}`;
    if (!serviceExist.description) {
      navigate("/maintaince");
    }
    setService(serviceExist);
    setImages(serviceExist?.imageProductService);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="px-6 lg:px-20 pb-10">
      {/* Navigation */}
      <div className="flex justify-between  mb-8 lg:mb-10">
        <Link
          to="/"
          className="cursor-pointer rounded-[30px] flex items-center justify-center px-4 py-2 space-x-4 bg-white border border-solid border-[#A0A0A0]"
        >
          <img src={IcArrow} alt="back" />
          <p className="block text-[16px]">Back</p>
        </Link>

        <div className="flex space-x-4 items-center justify-center">
          <Link to="/">Home</Link>
          <span>&gt;</span>
          <div className="uppercase text-[#019EE2] border-b border-solid border-[#019ee2]">
            {service.title}
          </div>
        </div>
      </div>
      <h3 className="text-[40px] md:text-[50px] font-semibold pb-[20px] md:pb-[50px] pt-8 text-[#383838] leading-[75px] text-center">
        {service.title}
      </h3>
      {/* Image slider */}
      {images && (
        <div className="mb-[50px] flex items-center justify-center gap-x-2 overflow-hidden">
          <div className="swiper-button-back w-[100px] h-[50px] cursor-pointer active:ring-0 focus:ring-0 active:bg-none">
            <img src={IcArrowLeft} alt="arrow-left" className="text-gray-500" />
          </div>
          <Swiper
            modules={[Autoplay, EffectFade, Navigation]}
            loop={true}
            speed={1500}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loopFillGroupWithBlank={true}
            breakpoints={{
              400: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: images.length > 2 ? 2 : images?.length,
                spaceBetween: 30,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: images.length > 3 ? 3 : images?.length,
                spaceBetween: 25,
                slidesPerGroup: 1,
              },
            }}
            navigation={{
              prevEl: ".swiper-button-back",
              nextEl: ".swiper-button-go",
            }}
            className="mySwiper px-0 md:px-6"
          >
            {images.map((image, idx) => (
              <SwiperSlide key={idx}>
                <div className="w-full h-full">
                  <img
                    src={image}
                    alt={`images-${idx}`}
                    className="w-[500px] mx-auto h-[250px] rounded-[20px] object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-go w-[100px] h-[50px] cursor-pointer">
            <img src={IcArrowRight} alt="arrow-right" />
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center w-max mb-[30px]">
        <h3 className="text-3xl text-[#383838] font-semibold w-max">
          Introduction
        </h3>
      </div>
      <p
        className="text-sm md:text-[16px] text-justify font-normal leading-[35px] text-[#444] mb-12"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(service.description),
        }}
      ></p>
    </div>
  );
};

export default Service;
