import React, { useState } from "react";
import { motion } from "framer-motion";
import DOMPurify from "dompurify";

import { Swiper, SwiperSlide } from "swiper/react";
import { HashLink } from "react-router-hash-link";

import { useScrollPosition } from "../../hooks/useScrollPositiion";
import utils from "../../utils/utils";
import Banner1Img from "../../assets/images/new/banner_1.png";
import Banner2Img from "../../assets/images/new/banner_2.png";
import Banner3Img from "../../assets/images/new/banner_3.png";
import {
  sectionVariant,
  showFromTop,
  showFromBottom,
} from "../../utils/variants";

import { Homepage } from "../../@types/lang";

import { Autoplay, EffectFade } from "swiper";

type Props = {
  lang: Homepage;
};

const About = ({ lang }: Props) => {
  const { scrollWithOffset } = utils;

  const scrollPosition = useScrollPosition();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [heroes, setHeroes] = useState<any[]>([
    Banner1Img,
    Banner2Img,
    Banner3Img,
  ]);
  return (
    <>
      {/* ABOUT SECTION */}
      <div>
        <div className="w-full relative max-h-[600px]">
          <Swiper
            centeredSlides={true}
            loop={true}
            speed={1500}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
          >
            {heroes.map((hero, idx) => (
              <SwiperSlide key={idx}>
                <div className="mx-auto h-full relative w-max" key={idx}>
                  <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-auto bg-black/30"></div>
                  <img src={hero} alt="hero" className="h-full w-full" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute top-[20%] md:top-[25%] w-3/4 h-full flex flex-col left-1/2 z-10 gap-2 -translate-x-[50%] text-center mx-auto">
            <h3 className="font-semibold text-[30px] lg:text-[36px] leading-[40px] lg:leading-[75px] text-[#fff]">
              {lang.banner_title || "-"}
            </h3>
            <HashLink
              scroll={(el) =>
                scrollWithOffset(el, scrollPosition ? -100 : -155)
              }
              to="/#service"
              className="bg-[#019EE2] hover:bg-[#2F5597] text-center w-[300px] mt-10 mx-auto rounded-[10px] px-[10px] py-3 text-white text-lg cursor-pointer"
            >
              Get Started
            </HashLink>
          </div>
        </div>
        {/* About section */}
        <div className="w-[90%] mx-auto flex flex-col space-y-8 relative z-10 -translate-y-2 lg:-translate-y-4">
          <div
            className="w-full px-8 pt-4 pb-4 md:pb-1 rounded-[20px]"
            style={{
              background:
                "linear-gradient(268.09deg, #FFFFFF 17.07%, rgba(69, 90, 100, 0.3) 100.86%)",
            }}
          >
            <div className="flex justify-between items-center py-1" id="about">
              <h3 className="text-white text-xl lg:text-3xl font-semibold">
                About us
              </h3>
            </div>
            <div className="bg-[url('/src/assets/images/hero-bg-text.png')] bg-no-repeat h-[250px] md:h-[270px] xl:h-[180px] pt-4 xl:pt-6">
              <p
                className="text-sm md:text-[18px] leading-[22px] md:leading-[35px] text-[#444] mt-2 text-justify"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(lang.about_text),
                }}
              ></p>
            </div>
          </div>
          {/* VISI MISI SECTION */}
          <motion.div
            variants={sectionVariant}
            initial="hidden"
            whileInView="visible"
            className="grid grid-cols-1 lg:grid-cols-3 items-baseline gap-x-0 lg:space-x-8 gap-y-8 lg:gap-y-0 w-[95%] lg:w-[100%] mx-auto"
          >
            <motion.div
              variants={showFromTop}
              className="flex flex-col items-center justify-center text-center space-y-4"
            >
              <h3 className="text-xl text-[#FAB44B] font-bold uppercase">
                Our Vision
              </h3>
              <p className="text-[16px] leading-[25px] w-[300px] md:w-[60%] lg:w-[90%] text-justify">
                {lang.about_vision}
              </p>
            </motion.div>
            <motion.div
              variants={showFromBottom}
              className="flex flex-col items-center justify-center text-center space-y-4"
            >
              <h3 className="text-xl text-[#FAB44B] font-bold uppercase">
                Our Mission
              </h3>
              <p className="text-[16px] leading-[25px] w-[300px] md:w-[60%] lg:w-[90%] text-justify">
                {lang.about_mission}
              </p>
            </motion.div>
            <motion.div
              variants={showFromTop}
              className="flex flex-col items-center justify-center text-center space-y-4"
            >
              <h3 className="text-xl text-[#FAB44B] font-bold uppercase">
                Our Value
              </h3>
              <p className="text-[16px] leading-[25px] w-[300px] md:w-[60%] lg:w-[90%] text-justify">
                {lang.about_value}
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default About;
