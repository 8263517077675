/* eslint-disable eqeqeq */
import React, { createContext, useContext, useState, useEffect } from "react";
import en from "../lang/en";
import idn from "../lang/id";

const LangContext = createContext<any>(null);

type Props = {
  children: React.ReactNode;
};

export const LangCtx: React.FC<Props> = ({ children }) => {
  const [lang, setLang] = useState<string>("en");
  const [t, setTranslate] = useState<any>(en);

  const getExistProduct = (id) => {
    // return en.categoryProducts.find((product) => product.id === id);
    // Mendapatkan product dengan mengirimkan id tsb
    return lang === "en"
      ? en.categoryProducts.find((product) => product.id === id)
      : idn?.categoryProducts.find((product) => product.id === id);
  };
  const getExistService = (id) => {
    // return en.services.find((service) => service.id == id);
    // Mendapatkan service dengan mengirimkan id service tsb
    return lang === "en"
      ? en.services.find((service) => service.id == id)
      : idn?.services.find((service) => service.id == id);
  };

  const setLanguage = (lang) => {
    setLang(lang);
    localStorage.setItem("lang", lang);
  };

  useEffect(() => {
    const langLocal = localStorage.getItem("lang") || "en";
    if (!langLocal) return;
    setLang(langLocal);
    if (lang === "en") {
      setTranslate(en);
    } else if (lang === "id") {
      setTranslate(idn);
    }
  }, [lang]);

  const value = {
    t,
    getExistProduct,
    getExistService,
    lang,
    setLanguage,
    setLang,
  };

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};

export const UseLangContext = () => useContext(LangContext);
