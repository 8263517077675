import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const Maintaince = (props: Props) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-10 px-10">
      {/* <img src={MtImg} alt="Maintaince" /> */}
      <h1 className="text-3xl text-[#6a6a6a]">
        This pages is under development
      </h1>
      <Link
        to="/"
        className="bg-[#019EE2] text-center w-full lg:max-w-[383px] rounded-[10px] px-[10px] py-4 text-white text-lg cursor-pointer"
      >
        Go Back
      </Link>
    </div>
  );
};

export default Maintaince;
