import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Product from "./pages/Detail/Product";
import Service from "./pages/Detail/Service";
import Maintaince from "./pages/Maintaince";
import ScrollToTop from "./components/ScrollToTop";

import Layout from "./layouts";

import { LangCtx } from "./contexts/Lang";

function App() {

  return (
    <LangCtx>
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/maintaince" element={<Maintaince />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/service/:id" element={<Service />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </LangCtx>
  );
}

export default App;
