import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="py-[40px] text-white flex items-center justify-center space-x-2 h-12 bg-[#1C323F]">
      <span>Copyright 2022</span>
      <div className="w-[10px] h-[10px] bg-white rounded-full"></div>
      <span>All right reserved</span>
      <div className="w-[10px] h-[10px] bg-white rounded-full"></div>
      <span>OSB</span>
    </div>
  );
};

export default Footer;
