import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { sectionVariant, showContent } from "../../utils/variants";

import { Homepage, Service } from "../../@types/lang";

type Props = {
  lang: Homepage;
  services: Service[];
};

const Services = ({ lang, services }: Props) => {
  const LinkMotion = motion(Link);

  return (
    <>
      <div>
        <div className="px-6 lg:px-20 flex flex-col space-y-4 mb-8 lg:mb-10">
          <h5 className="text-[#019EE2] text-xl font-semibold">
            {lang.service_title}
          </h5>
          <h3 className="text-[#383838] text-xl lg:text-3xl font-bold">
            {lang.service_subtitle}
          </h3>
        </div>
        {/* LIST SERVICE */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          whileInView="visible"
          className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6 lg:px-20"
        >
          {services.map((service) => (
            <LinkMotion
              key={service.id}
              custom={service.id}
              variants={showContent}
              to={`/service/${service.id}`}
              style={{ background: `url(${service.imageUrl})` }}
              className="!bg-no-repeat !bg-center !bg-cover py-6 text-center rounded-[10px] relative z-0 cursor-pointer h-full"
            >
              <div className="bg-[#000]/50 absolute top-0 left-0 w-full h-full rounded-[10px]"></div>
              <h3 className="text-white text-lg relative z-10">
                {service.title}
              </h3>
            </LinkMotion>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default Services;
