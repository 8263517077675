import OpsisImg from "../assets/images/opsis.png";
import SkiImg from "../assets/images/ski_icon.png";
import FavesImg from "../assets/images/fives_icon.png";

import {
  opsisProductImage,
  skiProductImage,
  fivesProductImage,
  certiFives,
  certiSki,
  certiOpsis,
} from "../data/products";
import {
  serviceImage,
  serviceImgElectrical,
  serviceImgMechanical,
  serviceImgEnvironment,
} from "../data/services";
import { Lang } from "../@types/lang";

const id: Lang = {
  // HOMEPAGE
  homepage: {
    banner_title: "Outstanding Service, Outstanding Quality, Outstanding You.",
    about_title: "About Us",
    about_subtitle:
      "Kami adalah perusahaan yang bergerak di bidang Teknik, dan Jasa Lingkungan",
    about_text:
      "Berdiri sejak 29 Februari 2012, PT OMEGA SOLUSINDO BERSAMA adalah perusahaan yang bergerak di bidang Mekanikal, Elektrikal dan Lingkungan. Beberapa profesional di bidangnya dengan visi yang sama telah bergabung sejak tahun 2012 untuk membantu menjadikan PT. OMEGA SOLUSINDO BERSAMA menjadi perusahaan jasa terkemuka yang terpercaya.",
    about_vision:
      "Menjadi salah satu perusahaan nasional terkemuka di bidang mekanikal, elektrikal & lingkungan, yang terus berkembang mengikuti kemajuan teknologi masa depan.",
    about_mission:
      "Memberikan pelayanan secara profesional dengan kualitas dan kepuasan kepada pelanggan dengan tetap memenuhi standar kesehatan dan keselamatan kerja serta lingkungan sesuai dengan peraturan yang berlaku.",
    about_value:
      "PT Omega Solusindo Bersama memberikan jaminan mutu & kualitas pelayanan terbaik kepada pelanggan yang dikelola secara profesional untuk memenuhi keinginan, kebutuhan dan kepuasan pelanggan serta menjadi kontribusi bagi bangsa dan negara.",
    service_title: "Our Service",
    service_subtitle: "Memberikan pelayanan terbaik untuk Anda",
    product_title: "Our Product",
    product_subtitle: "Kami adalah distributor resmi",
    contact_text:
      "Hubungi kami untuk penawaran harga atau jika Anda ingin tahu lebih banyak tentang layanan dan produk kami.",
    contact_title: "GET IN TOUCH",
    contact_subtitle:
      "Gunakan formulir di bawah ini untuk mengirimkan kami email",
    client_title: "Our Client",
  },

  // Product
  categoryProducts: [
    {
      id: "1",
      title: "Opsis",
      imageUrl: OpsisImg,
      description: `
         <p style="margin-bottom:1rem;">
        OPSIS environmental monitoring systems telah diuji dan disetujui oleh sejumlah lembaga dan otoritas yang diakui secara internasional. Sistem pemantauan lingkungan OPSIS dicirikan oleh keandalan, daya tahan, dan biaya pemeliharaan yang rendah; banyak referensi yang akan menjamin hal ini. 
        </p>
         <p style="margin-bottom:1rem;">
        Sistem OPSIS didasarkan pada metode DOAS/FTIR non-kontak, menggunakan jalur optik yang
        dapat beroperasi melintasi saluran. Cahaya optik diangkut dalam serat optik ke penganalisis dan satu penganalisis dapat mengoperasikan beberapa jalur yang menyediakan misalnya laju aliran gas buang dan konsentrasi debu. Hasil pengukuran kemudian diteruskan secara real-time ke sistem kontrol proses menggunakan salah satu protokol komunikasi industri standar yang didukung. Dari sana, hasilnya dapat digunakan misalnya untuk pemantauan emisi dan optimalisasi proses
        </p>`,
      shortDescription:
        "Satu sistem OPSIS dapat mengukur semua komponen gas yang relevan seperti NOX, SO2, CO, CO2, H2O, HCl, HF, NH3, CH4, dan Hg",
      productsInCategory: [
        {
          title: "EMITTER & RECEIVER FOR CEMS MANUAL OR AUTOMATIC  CALIBRATION",
          description:
            "Unit Emitter dan Receiver menciptakan jalur cahaya melalui diameter internal cerobong untuk OPSIS Emission Monitoring Sytem. Cahaya dihasilkan oleh Xenon Lamp di dalan Emitter dan diproyeksikan ke Receiver. Dari Receiver, kemudian dilanjutkan ke Analyser melalui Kabel Fibre Optic. Baik Emitter maupun Receiver  dipasang pada Pipa flanges external. Emitter menggunakan Power Supply, yang membutuhkan sambungan tenaga listrik.",
          imageUrl: opsisProductImage.img1,
          id: 1,
        },
        {
          title: "MULTI-COMPONENT ANALYSERS FOR CEMS",
          description:
            "Analyser OPSIS menerima cahaya dari satu ataupun lebih jalur cahaya melalui kabel fibre optic dan menyediakan data untuk presentasi melalui OPSIS software. Analyser memiliki spektrometer berkinerja tinggi. System menyempurnakan hasil dengan menghitung margin eror dari setiap nilai dan dengan mencatat proporsi cahaya yang ditransmisikan yang diterima oleh analyser. Hal ini memungkinkan verifikasi data sekaligus system. ",
          imageUrl: opsisProductImage.img2,
          id: 2,
        },
        {
          title: "OXYGEN ANALYSER FOR CEMS ",
          description:
            "OPSIS Oksigen analyser dirancang untuk mengukur oksigen dalam aplikasi pemantauan emisi industri, proses dan berkelanjutan. Karena oksigen analyser mengukur kandungan oksigen di dalam gas buang secara langsung, maka tidak memerlukan lagi sistem sistem ekstraksi sample.",
          imageUrl: opsisProductImage.img3,
          id: 3,
        },
        // {
        //   title: "POWER SUPLLY FOR EMITTER & RECEIVER",
        //   description:
        //     "Power supply mengoperasikan Xenon lamp yang digunakan oleh OPSIS Emitter. Terhubung dengan unit",
        //   imageUrl: opsisProductImage.img4,
        //   id: 4,
        // },
        // {
        //   title: "FIBRE OPTIC",
        //   description:
        //     "Kabel fibre optic diperlukan oleh OPSIS system untuk membawa cahaya yang diterima dari jalur cahaya ke analyser. Ada 8 tipe kabel fiber optic ",
        //   imageUrl: opsisProductImage.img5,
        //   id: 5,
        // },
        // {
        //   title: "MULTI-COMPONENT ANALYSERS FOR AQMS",
        //   description:
        //     "The OPSIS Analyser is the central unit of an OPSIS ambient air quality monitoring system. It receives light from one or more light paths via a fibre optic cable and provides data for presentation through OPSIS software. The analyser has a high-performance spectrometer.",
        //   imageUrl: opsisProductImage.img6,
        //   id: 6,
        // },
        // {
        //   title: "EMITTER & RECEIVER FOR AQMS",
        //   description:
        //     "A light emitter and receiver set creates the open light path of an OPSIS air quality monitoring system. Light is generated by a xenon lamp in the emitter and projected to the receiver. From the receiver, it is passed to the analyser through a fibre optic cable",
        //   imageUrl: opsisProductImage.img7,
        //   id: 7,
        // },
      ],
      applicationsInCategory: [
        "Peleburan Aluminium ",
        "Industri Semen ",
        "Industri Kimia ",
        "Pemantauan Merkuri ",
        "Penambangan & Peleburan ",
        "Pembangkit Listrik ",
        "Pabrik Baja ",
        "Pabrik Produksi Asam Sulfat",
        "Dan banyak lagi",
      ],
      certificatesInCategory: certiOpsis,
    },
    {
      id: "2",
      title: "Ski",
      imageUrl: SkiImg,
      description: `
      <p style="margin-bottom:1rem;"> 
      Kualitas produk SKI dibuktikan dengan pengukuran di  calibration bench in-house dan beberapa ratus sertifikat kalibrasi yang dikeluarkan oleh Landesbetriebes für Mess- und Eichwesen dan labratorium pihak ketiga lainnya.
      </p>

      SKI mencakup bidang yang luas,  dari teknologi pengukuran aliran berbasis <div style="font-weight: bold; display:inline;">Differential Pressure </div> termasuk layanan di sekitar bidang ini. Mengapa Differential Pressure untuk CEMS?
      <ul style="list-style: none;">
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Diterima secara universal dan di seluruh dunia (dan diterima oleh hampir semua otoritas).</li>
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Sangat kuat dan tahan lama.</li>
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Teknologi ekonomis.</li>
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Cocok untuk sebagian besar aplikasi.</li>
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div>Sangat fleksibel.</li>
        <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Persyaratan perawatan yang sangat rendah </li>
      </ul>
      `,
      shortDescription:
        "Instrumen SKI digunakan di mana pun gas, uap jenuh atau uap panas, atau aliran cairan harus diukur dengan akurat",
      productsInCategory: [
        {
          title: "AccuFlo®QAL",
          description:
            "SKI AccuFlo®QAL adalah sistem pengukuran otomatis bersertifikat QAL 1 untuk fluegas. Sistem ini mudah untuk diinstal dan dioperasikan.  Design yang sangat flexible memungkinkan instrumen untuk dipasang pada stacks dan ducts yang ada, serta prinsip desain yang kokoh membuat sistem ini sesuai untuk pengaplikasian dimana teknologi flow meter stack lain sudah tidak dapat digunakan lagi dikarenakan prinsip pengukurannya. Hal ini berlaku untuk pengaplikasian exhaust gas bertemperatur sangat tinggi, uap gas jenuh dan flue gas dengan kadar dust atau particle yang tinggi.",
          imageUrl: skiProductImage.img1,
          id: 1,
        },
        {
          title: "Accu Mind",
          description:
            "AccuMind® menawarkan berbagai algoritma kalkulasi didalam satu alat dengan filosofi pengoperasian yang seragam dan mudah untuk diprogram.",
          imageUrl: skiProductImage.img2,
          id: 2,
        },
      ],
      applicationsInCategory: [
        "Industri Tenaga Listrik",
        "Industri Bangunan dan Bahan Baku",
        "Teknologi Lingkungan",
        "Industri Kimia dan Petrokimia",
        "Industri Baja dan Metalurgi",
        "Dan banyak lagi",
      ],
      certificatesInCategory: certiSki,
    },
    {
      id: "3",
      title: "Fives",
      imageUrl: FavesImg,
      description: `Sebagai bagian dari sistem pembakaran, Fives Pillard menangani semua tugas yang muncul, mulai dari penyimpanan bahan bakar dan transportasi internal hingga pembakar dan ruang pembakaran, termasuk teknologi keselamatan dan otomatisasi terkait serta sistem tambahan yang diperlukan. Fives Pillard menawarkan teknologi <div style="display:inline; font-weight:bold">Backscatter</div> yang canggih untuk memantau stack dan kebocoran bag filter. Sebuah solusi terbaik untuk melacak dust emission serta memenuhi standar dan peraturan industri. Peralatan ini sangat andal, berkat sistem fiber optic yang memungkinkan perangkat bekerja secara jarak jauh dari stack.`,
      shortDescription:
        "Berbagai macam Dust Emission Monitor yang mencakup sebagian besar aplikasi industri",
      productsInCategory: [
        {
          title: "GP4000-H",
          description:
            "dengan teknologi backscattering terkini Untuk stacks dan after bag filter monitoring, merupakan solusi terbaik untuk memantau emisi dust dan opacity yang memenuhi standar regulasi dan sertifikasi. Melalui teknologi Back scattering, pancaran sinar lampu LED memberikan pengukuran yang akurat ( tidak sensitif terhadap cahaya sekitar). Fungsi self-checking and correction soiling system menghindari terjadinya distorsi terhadap sinyal pengukuran. ",
          imageUrl: fivesProductImage.img1,
          id: 1,
        },
        {
          title: "GP4000H-C",
          description:
            "Merupakan solusi terbaru dari Fives untuk Dust dengan konsentrasi rendah untuk memastikan pengukuran secara berkala dan kontrol terhadap dust emissions untuk semua jenis indutsri stack. ",
          imageUrl: fivesProductImage.img2,
          id: 1,
        },
      ],
      applicationsInCategory: [
        "Pengolahan udara dan limbah ",
        "Semen ",
        "Logam besi ",
        "Mineral ",
        "Dan banyak lagi",
      ],
      certificatesInCategory: certiFives,
    },
  ],

  // Service
  services: [
    {
      id: 1,
      title: "Environment",
      description: `
      Continuous Emission Monitoring System (CEMS) adalah metode pemantauan emisi dari cerobong asap lokasi industri seperti pembangkit listrik, pabrik manufaktur, dan fasilitas lain di mana pembakaran sumber bahan bakar terjadi. CEMS dengan teknologi terdepan sangat penting untuk pengawasan dan pemantauan cerobong asap secara real-time, untuk memastikan emisi dapat dikontrol secara tegas sesuai dengan standar emisi yang diizinkan. Hal ini merupakan persyaratan hukum di banyak negara di seluruh dunia. Pada tanggal 24 Mei 2021, Peraturan Menteri Lingkungan Hidup dan Kehutanan No. 13 Tahun 2021 tentang Sistem Pemantauan Emisi Berkelanjutan untuk Emisi Industri diberlakukan di Indonesia dan mulai berlaku pada hari yang sama. Peraturan ini mewajibkan industri tertentu untuk memasang Sistem Pemantauan Emisi Berkelanjutan (CEMS) dan menghubungkan datanya ke Sistem Informasi Pemantauan Emisi Industri Berkelanjutan (SISPEK) yang dioperasikan oleh pemerintah. 
        <br/>
        <br/>
        PT Omega Solusindo Bersama berspesialisasi dalam Sistem Pemantauan Emisi Berkelanjutan (CEMS). Lingkup pekerjaan kami meliputi penyediaan, pengembangan, pemasangan, integrasi, pengujian, kalibrasi dan commissioning sistem pemantauan lengkap. Orang-orang kami memiliki pengalaman yang luas dalam layanan dan sistem pemantauan lingkungan.  PT Omega Solusindo Bersama bermitra dengan beberapa pengembang CEMS yang paling terdepan untuk :
          <ol style="list-style: decimal; padding-left:1rem; padding-top:1rem;">
            <li style="padding: 0.2rem 0"><a href="/product/1" style="color:#019EE2;border-bottom:1px solid #019ee2;">Gas Analyser</a></li>
            <li style="padding: 0.2rem 0"><a href="/product/3" style="color:#019EE2;border-bottom:1px solid #019ee2;">Opacity & Particulate Analyser</a></li>
            <li style="padding: 0.2rem 0"><a href="/product/2" style="color:#019EE2;border-bottom:1px solid #019ee2;">Flow, Temperature and Pressure Analyser</a></li>
          </ol>
          <br/>
          Kami menawarkan berbagai macam layanan pengujian dan analisis, dengan menggunakan instrumentasi terbaru. PT Omega Solusindo Bersama memiliki pengalaman yang luas dalam melakukan layanan kalibrasi CEMS termasuk:
          <br/><br/>
          <ul style="list-style: none;">
            <div style="display:flex; align-items:center; gap:3rem">
              <div>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Relative Accuracy Test Audits (RATA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Relative Accuracy Audit (RAA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Response Correlation Audit (RCA)</li>
              </div>
              <div>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Cylinder Gas Audit (CGA)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Data Interfacing System (DIS)</li>
                <li style="padding: 0.2rem 0; display:flex; align-items:center; gap: 0.8rem"><div style="width:10px; height: 10px; background:#019EE2; border-radius:100%"></div> Data Acquisition System (DAS)</li>
              </div>
            </div>
          </ul>
          <br/>
          Kami juga memberikan konsultasi sehubungan dengan persyaratan lingkungan, dan sistem informasi.
        `,
      imageUrl: serviceImage.imgEnv,
      imageProductService: serviceImgEnvironment,
    },
    {
      id: 2,
      title: "Mechanical",
      imageUrl: serviceImage.imgMech,
      description:
        "Salah satu spesialisasi kami adalah pekerjaan Plumbing yang meliputi Main Equipment seperti Pompa Transfer, Pompa Booster, Pompa Sumpit, dan juga pekerjaan pemipaan yang menggunakan berbagai jenis pipa seperti Black Steel, Galvanis, PPR, dan PVC, termasuk berbagai jenis pekerjaan katup atau valve seperti Pressure Reduce Valve (PRV), Water Hammer, Master Control Valve (MCV) dan lain sebagainya.Kami juga bisa menyediakan Sanitare sesuai kebutuhan menyesuaikan Budget maupun kebutuhan untuk keperluan khusus lainnya yang mendukung Kinerja serta menunjang estetika interior yang diinginkan.PT Omega Solusindo bersama  juga mengerjakan pekerjaan Hydrant dan Sprinkler mulai dari Pompa Hydrant Elektrik, Pompa Hydrant Engine, Pompa Jockey, dan juga pemipaannya serta pekerjaan katup atau valve yang mendukung sistem Hydrant dan Sprinkler dapat berfungsi sesuai standart dari dinas terkait. Tidak Hanya Perangkat Utama dan Instalasinya saja, namun juga Aksesoris lainnya seperti Hydran Box, Siamesse Connection Head, dan tentu saja Penanggulang Kebakaran Kecil dengan Menggunakan APAR. Kami juga mengerjakan bidang Pekerjaan Mekanikal Lainnya yang tidak kalah penting di dalam Pekerjaan Konstruksi yakni Pekerjaan Air Conditioner, baik dari Jenis Standar SOHO seperti type  AC Split wall, Ceiling conceal, Cassette, Ceiling Suspended. Tidak Hanya pada aplikasi kecil kami juga menyediakan juga kapasitas untuk  Aplikasi Gedung dan Perkantoran yang membutuhkan Ketelitian dan Keprofesionalan yang handal.",
      imageProductService: serviceImgMechanical,
    },
    {
      id: 3,
      title: "Electrical",
      imageUrl: serviceImage.imgElec,
      description:
        "PT Omega Solusindo Bersama mengerjakan bidang Pekerjaan Utama Elektrikal mulai dari Instalasi Listrik Standar Gedung baik Eksternal maupun Internal, termasuk Instalasi Tufur bawah tanah dan Instalasi menggunakan BusBar. Perangkat Utama Listrik Mulai dari Penyediaan Panel distribusi kecil, PHBUTR, sampai Panel-panel kelistrikan khusus, Juga menyediakan dan menginstalasi Generator Set, Trafo Step Down dan Step Up, Serta Panel Tegangan Menengah (Cubicle Set). Pekerjaan Elektrikal lainnya yang tidak kalah penting adalah Pemasangan dan Instalasi Grounding dan Penangkal Petir.",
      imageProductService: serviceImgElectrical,
    },
  ],

  // popular product item
  popularProducts: [
    {
      title: "EMITTER & RECEIVER FOR CEMS MANUAL OR AUTOMATIC  CALIBRATION",
      description:
        "Emitter and Receiver unit creates a light path across the internal diameter of a stack or duct for an OPSIS emissions monitoring system. Light is generated by a xenon lamp in the emitter and projected to the receiver. From the receiver, it is passed to the analyser through a fibre optic cable. Both the emitter and the receiver are mounted on external pipe flanges. The emitter uses a power supply unit, which requires an electrical connection to mains.",
      imageUrl: opsisProductImage.img1,
      id: 1,
      idCategoryProduct: 1,
    },
    {
      title: "MULTI-COMPONENT ANALYSERS FOR CEMS",
      description:
        "OPSIS Analysers receives light from one or more light paths via a fibre optic cable and provides data for presentation through OPSIS software. The analyser has a high-performance spectrometer. The system refines results by calculating margins of error for each value and by noting the proportion of transmitted light received by the analyser. This allows both data and system verification.",
      imageUrl: opsisProductImage.img2,
      id: 2,
      idCategoryProduct: 1,
    },
    {
      title: "OXYGEN ANALYSER FOR CEMS ",
      description:
        "The OPSIS Oxygen analyser is designed for measuring oxygen in industrial, process and continuous emissions monitoring applications. Since the oxygen analyser measures the oxygen contents in the flue gas directly, there is no need for sample extraction systems.",
      imageUrl: opsisProductImage.img3,
      id: 3,
      idCategoryProduct: 1,
    },

    {
      title: "AccuFlo®QAL",
      description:
        "SKI AccuFlo®QAL is an automatic measuring system certified according to the QAL 1 for fluegas volume flow measurement. The system is easy to install, operate and to put into operation. Its highly flexible design allows the instrument to be retrofitted to existing stacks and ducts and its robust design principle makes it suitable for applications in which other stack flow meter technology can no longer be used due to their measuring principle. This applies to applications with very high exhaust gas temperatures, water vapor saturated gases and flue gases with high dust or particle content.",
      imageUrl: skiProductImage.img1,
      id: 4,
      idCategoryProduct: 2,
    },
    {
      title: "Accu Mind",
      description:
        "The AccuMind® offers numerous calculation algorithms in one device with a uniform operating philosophy and is easy to program.",
      imageUrl: skiProductImage.img2,
      id: 2,
      idCategoryProduct: 2,
    },
    {
      title: "GP4000-H",
      description:
        "With advanced back scattering technology for stacks and after bag filter monitoring, it is the ultimate solution to monitor dust and opacity emission while meeting regulations and certifications. Through the back scattering technology, the LED light beam offers accurate measurement (insensitive to ambient light). The self-checking functions and correction soiling system avoid a distorted measurement signal.",
      imageUrl: fivesProductImage.img1,
      id: 4,
      idCategoryProduct: 2,
    },
    {
      title: "GP4000H-C",
      description:
        "Fives’ latest solution for low dust concentration to ensure continuous measure and control dust emissions to all stacks industries.",
      imageUrl: fivesProductImage.img2,
      id: 2,
      idCategoryProduct: 2,
    },
  ],
};

export default id;
