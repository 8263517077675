import ProductOpsis1 from "../assets/images/Productlist-Img/Img1.svg";
import ProductOpsis2 from "../assets/images/Productlist-Img/Img2.svg";
import ProductOpsis3 from "../assets/images/Productlist-Img/Img3.svg";
// import ProductOpsis4 from "../assets/images/OMEGA/POwer-SUpply-transformed.png";
// import ProductOpsis5 from "../assets/images/OMEGA/Fibre-Optic-transformed.png";
// import ProductOpsis6 from "../assets/images/OMEGA/AR500S-transformed.png";
import ProductOpsis7 from "../assets/images/Productlist-Img/Img7.png";

import ProductSki1 from "../assets/images/ski_prod_1.jpeg";
import ProductSki2 from "../assets/images/ski_prod_2.png";

import ProductFives1 from "../assets/images/fives_prod_1.jpeg";
import ProductFives2 from "../assets/images/fives_prod_2.png";

import CertificateSki from "../assets/certificate/ski_certificate.pdf";
import CertificateFives from "../assets/certificate/fives_certificate.pdf";

import CertificateOpsis1 from "../assets/certificate/opsis/1.pdf";
import CertificateOpsis2 from "../assets/certificate/opsis/2.pdf";
import CertificateOpsis3 from "../assets/certificate/opsis/3.pdf";

// Certificate

export const opsisProductImage = {
  img1: ProductOpsis1,
  img2: ProductOpsis2,
  img3: ProductOpsis3,
  // img4: ProductOpsis4,
  // img5: ProductOpsis5,
  // img6: ProductOpsis6,
  img7: ProductOpsis7,
};

export const skiProductImage = {
  img1: ProductSki1,
  img2: ProductSki2,
};
export const fivesProductImage = {
  img1: ProductFives1,
  img2: ProductFives2,
};
export const certiSki = [CertificateSki];
export const certiFives = [CertificateFives];
export const certiOpsis = [
  CertificateOpsis1,
  CertificateOpsis2,
  CertificateOpsis3,
];
