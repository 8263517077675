import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

import { Homepage, CategoryProduct, PopularProduct } from "../../@types/lang";

type Props = {
  lang: Homepage;
  products: CategoryProduct[];
  popularProducts: PopularProduct[];
};

const Products = ({ products, lang, popularProducts }: Props) => {
  return (
    <>
      <div className="flex flex-col space-y-[50px]">
        <div className="flex flex-col justify-center space-y-4 px-6 lg:pl-20">
          <h5 className="text-[#019EE2] text-xl font-semibold">
            {lang.product_title}
          </h5>
          <h3 className="text-[#383838] text-xl lg:text-3xl font-bold">
            {lang.product_subtitle}
          </h3>
        </div>
        {/* Marquee */}
        <Marquee speed={50} pauseOnClick pauseOnHover>
          {popularProducts?.map((item, idx) => (
            <div
              key={idx}
              className="w-full text-center mx-2 border border-solid border-[#f1f1f1] p-2 rounded-[10px] hover:bg-gray-200 hover:border-[#1C323F]"
            >
              <img
                src={item.imageUrl}
                alt={item.title}
                className=" h-[90px] block mx-auto hover:scale-110 transition-all duration-300 mix-blend-multiply"
              />
            </div>
          ))}
        </Marquee>
        {/* products */}
        <div className="grid grid-cols-1  mx-auto xl:grid-cols-3 gap-6 px-6 lg:px-20 mb-8 lg:mb-10">
          {products.map((product) => (
            // Product Card
            <div
              key={product.id}
              className="bg-[#F5F5F5] flex flex-col items-center justify-center p-[40px] rounded-[20px] drop-shadow-lg max-w-[650px] space-y-8"
            >
              <div className="flex flex-col items-center justify-center space-y-2 w-[300px] min-h-[50px]">
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  className="w-[150px] h-[50px]"
                />
              </div>
              <p className="text-center text-lg text-[#444] min-h-[120px]">
                {product.shortDescription}
              </p>
              <Link
                to={`/product/${product.id}`}
                className="bg-[#019EE2] hover:bg-[#2F5597] rounded-[30px] text-center py-[20px] px-[40px] text-white"
              >
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;
