import React from "react";
import { HashLink } from "react-router-hash-link";
import { motion, AnimatePresence } from "framer-motion";

import IcDrop from "../assets/images/drop-ic.png";
import { useScrollPosition } from "../hooks/useScrollPositiion";
import utils from "../utils/utils";

import { ProductInService } from "../@types/lang";
import { UseLangContext } from "../contexts/Lang";

type Props = {
  idx: number;
  urlId?: string;
  product: ProductInService;
  onOpen: any;
  activeAccordion: number;
};

const Accordion = ({ product, idx, urlId, onOpen, activeAccordion }: Props) => {
  const { scrollWithOffset } = utils;
  const scrollPosition = useScrollPosition();
  const { lang } = UseLangContext();

  return (
    <div
      className={`mb-4 border-2 border-x-0 border-solid border-gray-100 ease-in-out duration-500  ${
        !(activeAccordion === idx)
          ? " rounded-[20px] border-b-0"
          : "rounded-t-[20px] rounded-b-[20px]"
      }`}
    >
      <div
        className="bg-white  border-r-0 border-l-0 border-t-0 border-b border-solid border-gray-100 rounded-[20px] flex flex-col md:flex-row items-center justify-between px-4 py-6 md:px-[40px] md:py-[20px] cursor-pointer drop-shadow-md"
        onClick={() => onOpen(idx)}
      >
        <div
          className={`${
            idx % 2 === 0 ? "order-first" : "order-last"
          } flex flex-col space-y-4 justify-center items-center md:items-start w-full md:w-max`}
        >
          <h3 className="text-lg lg:text-xl font-bold text-[#6a6a6a]">
            {product.title}
          </h3>
          <div className="flex space-x-2 items-center">
            <p className="text-[#FAB44B] border-b border-solid border-[#FAB44B] text-lg lg:text-xl">
              Read All
            </p>
            <img
              src={IcDrop}
              className={`ease-in-out duration-500 object-cover ${
                activeAccordion === idx ? "rotate-180" : ""
              }`}
              alt="icon"
            />
          </div>
        </div>
        <div className="w-[150px] md:w-[200px] lg:w-[300px] h-40 md:h-32">
          <img
            src={product.imageUrl}
            alt={product.title}
            className="object-contain h-full w-full"
          />
        </div>
      </div>
      <AnimatePresence>
        {activeAccordion === idx && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.01 }}
            exit={{ opacity: 0 }}
            className="p-[40px] border-gray-100 border-2 border-solid rounded-[10px] border-y-0 -translate-y-3 "
          >
            <div className="mb-6 flex md:flex-row flex-col space-y-4 md:space-y-0 justify-between items-center">
              <div className="w-max">
                <h3 className="text-lg text-[#383838] font-semibold w-max">
                  Description
                </h3>
              </div>

              <HashLink
                scroll={(el) =>
                  scrollWithOffset(el, scrollPosition ? -100 : -155)
                }
                to={`/product/${urlId}#contact`}
                className="rounded-[30px] text-center py-[15px] px-[30px] border border-solid border-[#019EE2] text-[#019EE2]"
              >
                {lang === "id" ? "Hubungi Kami" : "Feel free to contact us"}
              </HashLink>
            </div>
            <p className="text-sm md:text-[16px] leading-[35px] font-normal text-[#444] text-justify">
              {product.description}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
