export const sectionVariant = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.25,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

export const bannerChildSection = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

export const bannerChildButton = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const showFromTop = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};

export const showFromBottom = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 100 },
};

export const showContent = {
  visible: (i) => (i % 2 === 0 ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }),
  hidden: (i) =>
    i % 2 === 0 ? { opacity: 0, y: -100 } : { opacity: 0, y: 100 },
};
